.btn {
  @extend .inline-b;
  @extend .ttu;
  background: cl(primary);
  color: cl(white);
  font-size: rem(18px);
  line-height: rem(48px);
  font-family: $Montserrat;
  font-weight: 700;
  border: rem(1px solid cl(primary));
  cursor: pointer;
  border-radius: rem(25px);
  padding: rem(0 35px);
  letter-spacing: rem(1px);
  outline: 0;
  white-space: nowrap;
  &:hover {
    border-color: cl(primary);
    background: cl(white);
    color: cl(primary);
    text-decoration: none;
  }
  &:focus {
    box-shadow: none;
    border-color: cl(primary);
    background: cl(white);
    color: cl(primary);
  }
  &--primary-outline {
    background: none;
    color: cl(primary);
    border-color: cl(primary);
    border-width: 2px;
    &:hover {
      background: cl(primary, 0.1);
    }
  }
  &--black {
    background: cl(black);
    border-color: cl(black);
    &:hover {
      background: cl(black, 0.8);
    }
  }
  &--white {
    background: cl(white);
    border-color: cl(white);
    color: cl(primary);
    &:hover {
      background: cl(primary);
      border-color: cl(primary);
      color: cl(white);
    }
    &:focus {
      background: cl(primary);
      border-color: cl(primary);
      color: cl(white);
    }
  }
  &--sm {
    text-transform: none;
    font-size: rem(16px);
    line-height: rem(42px);
    font-weight: 600;
    border-radius: rem(22px);
    padding: rem(0 20px);
    letter-spacing: 0;
  }
}
