.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(30px);
  padding: rem(0 30px);
  height: rem(100px);
  transition: all 0.3s ease;
  @include media-breakpoint-down(xs) {
    padding: 0 1.5rem 0 1rem;
    gap: 0;
  }
  * {
    transition: all 0.3s ease;
  }
  &--white,
  &--inner {
    background: cl(white);
    box-shadow: rem(0 5px 10px cl(black, 0.1));
    .header-logo {
      svg {
        --color-primary: #374942;
        --color-white: #fff;
      }
      .green {
        display: block;
      }
      .white {
        display: none;
      }
    }
    .header-nav > ul {
      & > li {
        & > a {
          color: cl(primary);
          @include media-breakpoint-down(lg) {
            color: cl(white);
          }
        }
        &.active {
          & > a {
            color: cl(black);
            @include media-breakpoint-down(lg) {
              color: cl(primary);
            }
          }
        }
      }
    }
    .c-hamburger span {
      background: cl(primary);
      &:before,
      &:after {
        background: cl(primary);
      }
    }
    .c-hamburger--htx.active span:after,
    .c-hamburger--htx.active span:before {
      background: cl(white);
    }
  }
  &-left {
    overflow: hidden;
    flex-shrink: 0;
  }
  &-logo {
    display: block;
    width: rem(386px);
    @include media-breakpoint-down(xs) {
      width: rem(320px);
    }
    img {
      max-width: 100%;
      height: auto;
    }
    .green {
      display: none;
    }
    svg {
      --color-primary: #fff;
      --color-white: #374942;
      width: 100%;
    }
  }
  &-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: rem(30px);
    @include media-breakpoint-down(lg) {
      justify-content: flex-end;
    }
  }
  &-nav {
    //width:100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      position: fixed;
      right: -100%;
      top: 0;
      height: 100%;
      margin: 0;
      width: 30rem;
      padding: 6rem 0 0;
      background: cl(primary);
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      transition: all 0.3s linear;
      &.show {
        right: 0;
        box-shadow: rem(5px 0 10px rgba(0, 0, 0, 0.3));
      }
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    &-logo {
      position: absolute;
      left: 1rem;
      display: none;
      width: 20rem;
      height: auto;
      top: 1rem;
      @include media-breakpoint-down(lg) {
        display: block;
      }
      img {
        max-width: 100%;
        height: auto;
      }
      svg {
        --color-primary: #374942;
        --color-white: #fff;
        width: 100%;
      }
    }
    & > ul {
      display: flex;
      align-items: center;
      gap: rem(30px);
      list-style: none;
      margin: 0;
      @include media-breakpoint-down(lg) {
        height: 100%;
        width: 100%;
        overflow: auto;
        display: block;
      }
      & > li {
        position: relative;
        @include media-breakpoint-down(lg) {
          padding: 0;
          margin: 0;
          width: 100%;
          border-top: rem(1px solid cl(white, 0.1));
          &:last-child {
            border-bottom: rem(1px solid cl(white, 0.1));
          }
        }
        & > a {
          text-transform: uppercase;
          display: block;
          font-size: rem(19px);
          line-height: 1.1;
          font-weight: 700;
          color: cl(white);
          font-family: $Montserrat;
          @include media-breakpoint-down(lg) {
            display: block;
            padding: 1.5rem 2rem;
            text-align: center;
            color: cl(white);
          }
          &:hover {
            @include media-breakpoint-up(xl) {
              text-decoration: none;
              color: cl(light);
            }
          }
        }
        &.active {
          & > a {
            text-decoration: none;
            color: cl(light);
            @include media-breakpoint-down(lg) {
              background: white;
              color: cl(primary);
            }
          }
        }
        &:hover {
          & > .drop {
            display: block;
          }
        }
        &:last-child {
          & > .drop {
            left: auto;
            right: 0;
            &:before {
              left: auto;
              right: rem(20px);
            }
          }
        }
        & > .drop {
          &:before {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            top: rem(34px);
            left: rem(20px);
            border-left: rem(10px solid transparent);
            border-right: rem(10px solid transparent);
            border-bottom: rem(10px solid cl(primary));
          }
        }
        .drop {
          position: absolute;
          top: rem(17px);
          padding-top: rem(43px);
          left: 0;
          width: rem(250px);
          display: none;
          @include media-breakpoint-down(lg) {
            display: block;
            position: static;
            width: 100%;
            padding: 0;
            margin: 0;
          }
          ul {
            margin: 0;
            background: cl(primary);
            padding: rem(2px);
            list-style: none;
            box-shadow: rem(0 3px 10px cl(black, 0.4));
            @include media-breakpoint-down(lg) {
              box-shadow: none;
              padding: 0;
              background: none;
            }
            li {
              position: relative;
              &:not(:first-child) {
                @include media-breakpoint-up(xl) {
                  border-top: rem(1px solid cl(white, 0.1));
                }
              }
              a {
                font-family: $Montserrat;
                display: block;
                font-size: rem(16px);
                line-height: rem(20px);
                color: cl(white);
                padding: rem(12px 10px 12px 20px);
                @include media-breakpoint-down(lg) {
                  display: block;
                  padding: 1.5rem 2rem;
                  text-align: center;
                  border-top: rem(1px solid cl(white, 0.1));
                }
                &:hover {
                  text-decoration: none;
                  @include media-breakpoint-up(xl) {
                    background: cl(white);
                    color: cl(primary);
                  }
                }
              }
              &.active {
                & > a {
                  text-decoration: none;
                  background: cl(white);
                  color: cl(primary);
                }
              }
              &:hover {
                .drop {
                  display: block;
                }
              }
              .drop {
                top: 0;
                left: 99%;
                padding: 0 0 0 1%;
              }
            }
          }
        }
      }
    }
  }
  &-notification {
    flex-shrink: 0;
    width: rem(50px);
    height: rem(50px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: cl(primary);
    border: 1px solid cl(primary);
    color: cl(white);
    &:hover {
      text-decoration: none;
      color: cl(white);
    }
    @include media-breakpoint-down(xs) {
      background: cl(white);
      color: cl(primary);
      width: rem(40px);
      height: rem(40px);
      margin-right: rem(20px);
    }
  }
  &-buttons {
    position: relative;
    display: flex;
    gap: rem(28px);
    align-items: center;
    @include media-breakpoint-down(sm) {
      padding: 1rem;
      justify-content: space-between;
      gap: rem(10px);
      width: 100%;
    }
    .link {
      color: cl(white);
      font-size: rem(16px);
      &:focus,
      &:hover {
        outline: none;
        text-decoration: none;
        color: cl(primary);
      }
      @include media-breakpoint-down(xs) {
        background: none;
        border: 1px solid cl(primary);
        text-align: center;
        width: 48%;
        line-height: rem(42px);
        color: cl(primary);
      }
    }
    .btn {
      font-size: rem(20px);
      &:focus,
      &:hover {
        border-color: cl(primary);
      }
    }
  }
}

/*hamburger btn*/
.btn-lines {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: rem(2px);
  background-color: cl(white);
  content: "";
  transition: all 0.3s ease;
}
.c-hamburger {
  display: none;
  position: relative;
  z-index: 10;
  margin: 0;
  padding: 0;
  height: rem(20px);
  width: rem(26px);
  flex-shrink: 0;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease 0.3s;
  &:focus {
    outline: none;
  }
  @include media-breakpoint-down(lg) {
    display: block;
  }
  span {
    display: block;
    position: absolute;
    top: rem(9px);
    left: 0;
    right: 0;
    height: rem(2px);
    background: cl(white);
    transition: all 0.3s ease;
  }
  span::before {
    @extend .btn-lines;
    top: rem(-7px);
  }
  span::after {
    @extend .btn-lines;
    bottom: rem(-7px);
  }
  &--htx {
    background-color: transparent;
    &.active {
      &:before {
        display: none;
      }
      span {
        background: none;
      }
      span::before {
        top: 0;
        transform: rotate(45deg);
        background: cl(white);
      }
      span::after {
        bottom: 0;
        transform: rotate(-45deg);
        background: cl(white);
      }
    }
  }
}
/*hamburger btn*/
