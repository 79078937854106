.home {
  &-about {
    margin-top: rem(-45px);
    padding-bottom: rem(80px);
    position: relative;
    z-index: 10;
    @include media-breakpoint-down(xs) {
      margin-top: 3rem;
    }
    &-decor {
      position: absolute;
      &--1 {
        width:rem(175px);
        bottom:rem(50px);
        right:rem(50px);
        transform: scaleX(-1);
        height: auto;
        @include media-breakpoint-down(lg) {
          left:5%;
        }
      }
      &--2 {
        width:rem(405px);
        top:rem(203px);
        right:30%;
        height: auto;
        @include media-breakpoint-down(lg) {
          right:10%;
        }
        @include media-breakpoint-down(xs) {
          opacity: .5;
          right:0;
        }
      }
    }
    &-item {
      text-align: center;
      padding: rem(30px);
      background: cl(white);
      box-shadow:rem(0 8px 59px cl(black, .22));
      position: relative;
      z-index: 3;
      @include media-breakpoint-down(xs) {
        margin-bottom: 3rem;
      }
      img {
        display: block;
        height: rem(100px);
        width:auto;
        margin: rem(0 auto 15px);
        @include media-breakpoint-down(xs) {
          margin-bottom: rem(5px);
          height: rem(80px);
        }
      }
      strong {
        color:cl(primary);
        font-size: rem(100px);
        line-height: 1;
        font-weight: normal;
        letter-spacing: rem(1px);
        margin: rem(0 0 15px);
        display: block;
        font-family: $fontBold;
        @include media-breakpoint-down(xs) {
          font-size: rem(80px);
          margin-bottom: rem(5px);
        }
      }
      span {
        color:#969696;
        font-size: rem(20px);
        font-weight: 700;
        font-family: $Montserrat;
        display: block;
        @include media-breakpoint-down(xs) {
          font-size: rem(18px);
        }
      }
    }
  }
  &-slider {
    padding: rem(0 100px 0 405px);
    padding-bottom: 27%;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 100% auto;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      padding-bottom: 23%;
    }
    @include media-breakpoint-down(md) {
      padding-right:rem(16px);
      padding-left:19rem;
    }
    @include media-breakpoint-down(xs) {
      padding-left:rem(16px);
    }
    .tab-content>.tab-pane {
      @include media-breakpoint-down(xs) {
        display: block !important;
        opacity: 1 !important;
      }
    }
    .nav {
      border:0;
      margin: 0;
      @include media-breakpoint-up(sm) {
        position: absolute;
        left:0;
        top:rem(130px);
        flex-direction: column;
        width:rem(280px);
        gap:rem(50px);
      }
      @include media-breakpoint-down(xs) {
        display: none;
      }
      &-item {
        margin: 0;
      }
      &-link {
        font-size: rem(30px);
        line-height: 1;
        font-family: $font;
        color:#aea99a;
        padding: rem(0 0 0 45px);
        position: relative;
        border:0;
        &:before {
          content: "";
          position: absolute;
          left:0;
          height: 1px;
          width:rem(34px);
          background: #aea99a;
          top:rem(15px);
        }
        &.active,
        &:hover {
          color:cl(primary);
          &:before {
            background: cl(primary);
          }
        }
      }
    }
    .slider {
      &-count {
        font-size: rem(30px);
        color:#aea99a;
        position: absolute;
        left:0;
        top:0;
        span {
          color:cl(primary);
        }
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
      &-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        @include media-breakpoint-down(lg) {
          flex-direction: column;
        }
        @include media-breakpoint-down(xs) {
          //margin-bottom: 2rem;
        }
      }
      &-text {
        font-size: rem(24px);
        line-height: 1.4;
        margin-top: rem(60px);
        position: relative;
        z-index: 3;
        @include media-breakpoint-up(xl) {
          margin-top: rem(120px);
          margin-right: -23%;
          width:45%;
          &:before {
            content: "";
            position: absolute;
            top:rem(-50px);
            left:0;
            width:100%;
            height: rem(250px);
            background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 75%);
          }
        }
        @include media-breakpoint-down(xs) {
          margin-bottom: 2rem;
        }
        h2 {
          font-size: rem(80px);
          line-height: 1.1;
          margin: rem(0 0 20px);
          font-family: $fontBold;
          position: relative;
          z-index: 2;
          @include media-breakpoint-down(xs) {
            font-size: rem(60px);
          }
        }
        &-inner {
          position: relative;
          z-index: 2;
          @include media-breakpoint-up(xl) {
            width:rem(300px);
          }
        }
      }
      &-image {
        width:63%;
        position: relative;
        margin: rem(90px 80px 50px 110px);
        flex-shrink: 0;
        z-index: 1;
        @include media-breakpoint-down(md) {
          margin: 2rem;
          width:calc(100% - 4rem);
        }
        &-main {
          width:100%;
          padding-top: 75%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          box-shadow:rem(0 8px 59px cl(black, .22));
          position: relative;
          z-index: 2;
        }
        &-secondary {
          position: absolute;
          width:rem(320px);
          height: rem(320px);
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          @include media-breakpoint-down(md) {
            width: 10rem;
            height: 10rem;
          }
          &-top {
            top:rem(-90px);
            right:rem(-80px);
            @include media-breakpoint-down(md) {
              top:-2rem;
              right:-2rem;
            }
          }
          &-bottom {
            bottom:rem(-50px);
            left:rem(-90px);
            @include media-breakpoint-down(md) {
              bottom:-2rem;
              left:-2rem;
            }
          }
        }
      }
    }
    &-slogan {
      position: absolute;
      left:0;
      bottom: 23%;
      width:100%;
      z-index: 2;
      @include media-breakpoint-down(lg) {
        bottom: 0;
      }
    }
    &-name {
      display: block;
      text-align: center;
      text-transform: uppercase;
      color:cl(white);
      text-shadow:rem(0 8px 40px cl(black, .14));
      font-size: 16vw;
      line-height: 1;
      margin: 0 0 -2vw 0;
    }
    &-region {
      display: block;
      text-align: right;
      text-transform: uppercase;
      color:cl(white);
      text-shadow:rem(0 8px 40px cl(black, .25));
      font-size: 5vw;
      letter-spacing: rem(13px);
      padding-right: 19vw;
      line-height: 1;
    }
  }
  &-info {
    padding: rem(48px 16px 80px 16px);
    font-size: rem(24px);
    line-height: 1.4;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    @include media-breakpoint-up(xl) {
      padding-top: rem(150px);
      padding-left: rem(102px);
      padding-right: rem(102px);
    }
    @include media-breakpoint-down(md) {
      padding-bottom: 53%;
    }
    @include media-breakpoint-down(xs) {
      gap:rem(40px);
      padding-bottom: 3rem;
    }
    &-region {
      position: absolute;
      left:10%;
      top: 66%;
      z-index: 2;
      text-transform: uppercase;
      color:cl(white);
      text-shadow:rem(0 8px 40px cl(black, .25));
      font-size: 5vw;
      letter-spacing: rem(13px);
      line-height: 1;
      @include media-breakpoint-down(xs) {
        letter-spacing: rem(5px);
        text-shadow:rem(0 4px 8px cl(black, .25));
        left: 6%;
      }
    }
    &-side {
      width:45%;
      position: relative;
      z-index: 3;
      &--btm {
        @include media-breakpoint-up(lg) {
          margin-top: rem(336px);
        }
      }
      @include media-breakpoint-up(lg) {
        width:30%;
      }
      @include media-breakpoint-down(xs) {
        width:100%;
      }
    }
    h2 {
      font-size: rem(40px);
      line-height: rem(44px);
      font-weight: normal;
      color:cl(primary);
      margin: rem(0 0 45px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        margin: rem(0 0 25px);
      }
    }
    p {
      margin: rem(0 0 25px);
    }
    &-center {
      position: absolute;
      left:0;
      width:100%;
      z-index: 2;
      @include media-breakpoint-up(lg) {
        top:rem(150px);
      }
      @include media-breakpoint-down(md) {
        bottom:8%;
      }
      @include media-breakpoint-down(xs) {
        //bottom:13%;
        position: relative;
      }
      video {
        display: block;
        //margin: rem(36px auto 0);
        //width:rem(515px);
        margin: 1.5% auto 0;
        width: 28%;
        height: auto;
      }
      &-mask {
        position: absolute;
        left:0;
        top:0;
        width:100%;
      }
    }
    &-decor {
      position: absolute;
      z-index: 1;
      @include media-breakpoint-down(md) {
        opacity: .5;
      }
      &--1 {
        width:rem(175px);
        bottom: 8%;
        left: 25%;
        height: auto;
        @include media-breakpoint-down(lg) {
          left:5%;
        }
      }
      &--2 {
        width:rem(405px);
        top:rem(105px);
        right:1%;
        height: auto;
        @include media-breakpoint-down(lg) {
          right:10%;
        }
        @include media-breakpoint-down(xs) {
          opacity: .5;
          right:0;
        }
      }
    }
  }
  &-awards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:rem(50px);
    padding: rem(102px 185px 0);
    @include media-breakpoint-down(lg) {
      padding: rem(102px 16px 0);
    }
    @include media-breakpoint-down(md) {
      grid-gap:rem(20px);
    }
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1, 1fr);
      padding: 3rem 1rem;
    }
    &-item {
      border:2px solid cl(primary);
      padding: rem(10px);
      background: cl(white);
      box-shadow: rem(0 30px 40px cl(black, .14));
      &-inner {
        background: cl(secondary);
        display: flex;
        gap:rem(10px);
        padding: rem(15px);
        height: 100%;
        align-items: center;
        color:cl(white);
        @include media-breakpoint-between(sm, lg) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 100%;
          padding: rem(10px 20px 20px);
        }
        &:hover {
          text-decoration: none;
        }
      }
      &-logo {
        width:rem(200px);
        flex-shrink: 0;
        @include media-breakpoint-up(xl) {
          height: 100%;
        }
        @include media-breakpoint-down(lg) {
          max-width:100%;
        }
        @include media-breakpoint-down(xs) {
          width:10rem;
        }
        img {
          width:100%;
          height: 100%;
          object-fit: contain;
        }
      }
      h2 {
        color:cl(white);
        font-weight: normal;
        text-transform: uppercase;
        font-size: rem(30px);
        line-height: 1;
        margin: 0;
        @include media-breakpoint-down(xs) {
          font-size: rem(28px);
        }
      }
    }
  }
  &-slogan {
    position: relative;
    overflow: hidden;
    padding-bottom: rem(80px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
    h3 {
      font-size: rem(40px);
      line-height: 1.6;
      font-family: $fontBold;
      color:cl(primary);
      margin: 0;
      text-align: center;
      @include media-breakpoint-down(xs) {
        font-size: rem(28px);
      }
    }
    .container {
      position: relative;
      z-index: 2;
    }
    &-marquee {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      span {
        display:inline-block;
        //padding:0 0 0 100%;
        color:white;
        font-size: rem(273px);
        letter-spacing: rem(36px);
        text-transform: uppercase;
        text-shadow: rem(0 8px 40px cl(black, .14));
        white-space: nowrap;
        animation: marquee 100s infinite linear;
        @include media-breakpoint-down(xs) {
          font-size: 5rem;
          letter-spacing: rem(10px);
          text-shadow: rem(0 0 20px cl(black, .5));
        }
      }
    }
    @-webkit-keyframes marquee {
      0% {transform: translate(0, 0);}
      100% {transform: translate(-100%, 0)}
    }
    @keyframes marquee{
      0% {transform: translate(0, 0);}
      100% {transform: translate(-100%, 0)}
    }
  }
}