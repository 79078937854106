.inquiries {
  border: 1px solid cl(primary);
  box-shadow: rem(0 30px 40px cl(black, 0.14));
  padding: rem(6px);
  background: cl(white);
  &__inner {
    background: cl(secondary);
    padding: rem(20px);
    color: cl(white);
    font-size: rem(18px);
    line-height: 1.2;
    // overflow: hidden;
  }
  &__title {
    font-weight: normal;
    font-size: rem(24px);
    text-transform: uppercase;
  }
  &__sub {
  }
  &__ttl {
    font-size: rem(18px);
    text-transform: uppercase;
    font-weight: 700;
  }
  &__text {
    font-family: $Montserrat;
    font-size: rem(14px);
    line-height: 1.4;
    padding: rem(5px 0 0);
  }
  dl {
    font-family: $Montserrat;
    display: flex;
    margin: rem(0 0 10px);
    gap: rem(10px);
    dt {
    }
    dd {
      margin: 0;
      flex-grow: 1;
      @include media-breakpoint-down(lg) {
        overflow: hidden;
      }
    }
    p {
      margin: 0;
    }
    a {
      color: cl(white);
    }
    b,
    strong {
      font-family: inherit;
      font-weight: 700;
    }
  }
  &__email {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    @include media-breakpoint-down(lg) {
      text-overflow: ellipsis;
    }
    @include media-breakpoint-up(xl) {
      width: rem(185px);
      background: cl(secondary);
    }
    @include media-breakpoint-up(xl) {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: rem(70px);
        background: linear-gradient(
          to left,
          rgba(17, 111, 89, 0.85) 0%,
          rgba(17, 111, 89, 0) 100%
        );
      }
    }
    &:hover,
    &:focus {
      @include media-breakpoint-up(xl) {
        width: rem(250px);
        &:before {
          opacity: 0;
        }
      }
    }
    a {
        display: block;
    }
  }
}
