.filter {
  width: rem(262px);
  flex-shrink: 0;
  @include media-breakpoint-down(xs) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 6.25rem);
    z-index: 1001;
    background: white;
    padding: rem(30px);
    overflow-y: auto;
    transform: translateY(100%);
    visibility: hidden;
    transition: transform 0.3s ease, visibility 0.3s ease;
    &.show {
      transform: translateY(0);
      visibility: visible;
    }
  }
  &-open {
    position: fixed;
    top: 7rem;
    right: rem(20px);
    background: cl(dark);
    border: 1px solid cl(dark);
    font-family: $Montserrat;
    font-size: rem(16px);
    font-weight: 600;
    color: cl(white);
    padding: rem(13px 25px);
    border-radius: rem(30px);
    text-transform: uppercase;
    transition: background 0.3s ease, color 0.3s ease;
    z-index: 10;
    display:none;
    &:hover,
    &:focus {
      background: cl(white);
      color: cl(dark);
    }
    @include media-breakpoint-down(xs) {
      display: block;
    }
  }
  &-close {
    position: absolute;
    top: rem(20px);
    right: rem(30px);
    width: rem(35px);
    height: rem(35px);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #8d8a82;
    background: url(../img/close.png) no-repeat 50% 50%;
    background-size: rem(13px);
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
  }
  &-title {
    font-family: $Montserrat;
    font-size: rem(18px);
    font-weight: 700;
    color: cl(primary);
    display: block;
    text-transform: uppercase;
    margin: rem(0 0 25px);
  }
  &-row {
    display: block;
    margin: rem(0 0 16px);
  }
  &-label {
    font-family: $Montserrat;
    font-size: rem(16px);
    font-weight: 700;
    color: cl(primary);
    display: block;
    text-transform: uppercase;
    margin: rem(0 0 5px);
  }
  &-input,
  &-select {
    display: block;
    width: 100%;
    font-family: $Montserrat;
    height: rem(46px);
    border-radius: rem(3px);
    background: cl(white);
    border: 1px solid #aea99a;
    padding: rem(0 16px);
    font-size: rem(16px);
    color: cl(primary);
    @include placeholder(#969696);
    &:focus {
      outline: none;
      box-shadow: none;
      background-color: cl(white);
      border-color: cl(secondary);
    }
  }
  &-select {
    background-image: url(../img/select-arrow.png);
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: rem(16px auto);
    padding-right: rem(25px);
  }
  &-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(5px 0 0);
  }
  &-submit {
    background: cl(primary);
    border: 1px solid cl(primary);
    font-family: $Montserrat;
    font-size: rem(18px);
    font-weight: 700;
    color: cl(white);
    padding: rem(13px 25px);
    border-radius: rem(30px);
    text-transform: uppercase;
    transition: background 0.3s ease, color 0.3s ease;
    &:hover,
    &:focus {
      background: cl(white);
      color: cl(primary);
    }
  }
  &-reset {
    background: none;
    border: 0;
    font-family: $Montserrat;
    font-size: rem(16px);
    font-weight: 400;
    color: cl(primary);
    text-decoration: underline;
    padding: rem(5px);
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
