.news {
  position: relative;
  overflow: hidden;
  padding: rem(70px 0);
  .container {
    position: relative;
    z-index: 2;
  }
  &-wrapper {
    display: flex;
    gap:rem(46px);
    .news-list {
      flex-grow: 1;
    }
  }
  &-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap:rem(40px 35px)
  }
  &-item {
    width: calc((100% - 4.375rem) / 3);
    @include media-breakpoint-down(md) {
      width: calc((100% - 2.188rem) / 2);
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
  &-link {
    display: block;
    padding: rem(11px 9px 35px 0);
    border-right:1px solid cl(border);
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background: cl(border);
      right:0;
      top:0;
      height: 1px;
      width:rem(140px);
    }
    &:after {
      content: "";
      position: absolute;
      background: cl(border);
      right:0;
      bottom:0;
      height: 1px;
      width:100%;
      transition: all .3s ease;
    }
    &:hover {
      text-decoration: none;
      @include media-breakpoint-up(xl) {
        .news-img {
          box-shadow:rem(0 12px 54px cl(black, .29));
        }
        .news-more {
          background: cl(white);
          color:cl(primary);
        }
        &:after {
          width:calc(100% - 3.5rem);
        }
      }
    }
  }
  &-more {
    position: absolute;
    width:rem(42px);
    height: rem(42px);
    border-radius: 50%;
    border:1px solid cl(primary);
    bottom: rem(-21px);
    background: cl(primary);
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: all .3s ease;
  }
  &-img {
    display: block;
    margin: rem(0 0 25px);
    transition: all .3s ease;
    height: rem(270px);
    overflow: hidden;
    img {
      width:100%;
      height: 100%;
      object-fit: cover;
    }
  }
  h2 {
    font-size: rem(24px);
    line-height: rem(34px);
    color:cl(primary);
    font-weight: normal;
    margin: rem(0 0 15px);
    height: rem(102px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &-author {
    font-size: rem(18px);
  }
  &-information {
    font-size: rem(16px);
    font-family: $Montserrat;
    margin: rem(0 0 50px);
    strong, b {
      font-family: $Montserrat;
      font-weight: 700;
    }
    p {
      margin: rem(0 0 10px)!important;
    }
  }
}