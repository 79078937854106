.locations {
  background-position: 50% 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-bottom: 24%;
  position: relative;
  z-index: 3;
  overflow: hidden;
  .container {
    position: relative;
  }
  &-holder {
    position: relative;
    z-index: 2;
    &:before {
      content: "";
      position: absolute;
      bottom: rem(-2px);
      width:calc(100% + 0.250rem);
      height: rem(152px);
      left:rem(-2px);
      border:rem(2px solid cl(white));
      border-top:0;
    }
  }
  &-inner {
    display: flex;
    gap:rem(75px);
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: rem(45px);
    position: relative;
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: auto;
      gap:rem(20px);
      padding: rem(45px 25px);
    }
    img {
      width:rem(225px);
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(xs) {
        width:9rem;
      }
    }
  }
  &-text {
    color:cl(primary);
    font-size: rem(24px);
    line-height: 1.4;
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(xl){
      width:60%;
    }
    h3 {
      font-size: rem(40px);
      line-height: 1.2;
      font-weight: normal;
      margin: rem(0 0 35px);
      text-transform: uppercase;
      @include media-breakpoint-down(xs) {
        margin: rem(0 0 25px);
      }
    }
  }
  &-decor {
    position: absolute;
    transform: scaleX(-1);
    width:rem(175px);
    top:19%;
    right:0%;
    height: auto;
    @include media-breakpoint-down(lg) {
      //right:10%;
    }
    @include media-breakpoint-down(xs) {
      opacity: .5;
      width: 7.9375rem;
      top: 6%;
    }
  }
  &-slogan {
    position: absolute;
    left:0;
    bottom: 0;
    width:100%;
    z-index: 2;
  }
  &-name {
    display: block;
    text-align: center;
    text-transform: uppercase;
    color:cl(white);
    text-shadow:rem(0 8px 40px cl(black, .14));
    font-size: 16vw;
    line-height: 1;
    margin: 0 0 -3vw 0;
  }
  &-region {
    display: block;
    text-align: right;
    text-transform: uppercase;
    color:cl(white);
    text-shadow:rem(0 8px 40px cl(black, .25));
    font-size: 5vw;
    letter-spacing: rem(13px);
    padding-right: 10vw;
    line-height: 1;
    @include media-breakpoint-down(xs) {
      letter-spacing: rem(5px);
      text-shadow:rem(0 4px 8px cl(black, .25));
    }
  }
}